import axios from "axios"

const API_KEY = process.env.VUE_APP_FUSION_API_KEY;
const url = `${process.env.VUE_APP_FUSION_API_URL}/companies?API-KEY=${API_KEY}`;
const snapURL = `${process.env.VUE_APP_FUSION_API_URL}/companies/snap?API-KEY=${API_KEY}`;


// const url = `http://3plapi.test/companies?API-KEY=${API_KEY}`
// const url = `https://api-staging.3p-logistics.co.uk/`
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"
// const snapURL = `https://api-staging.3p-logistics.co.uk/companies/snap?API-KEY=${API_KEY}`;
export default {
  async getCompanies() {    
    let res = await axios.get(`${url}`);    
    return res.data.data;
  },
  async getSnapCompanies() {
    let res = await axios.get(`${snapURL}`);    
    return res.data.data;
  },
  async getCompany(companyId) {    
    let res = await axios.get(`${url}&id=${companyId}`);    
    return res.data.data[0];
  }
}