import Vue from 'vue'
import App from './App.vue'
import router from './router'
// Import Bulma
import './../node_modules/bulma/css/bulma.css';
// Material Icons
import './../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'
// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Export to Excel
import vuetify from './plugins/vuetify';

// Store Setup
import CompaniesUtil from '@/services/CompaniesUtil.js';
var store = {
  debug: false,
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '1.3.25',
    companies: [],
    loading: false,
    snackbar: false,
    text: ""
  },
  async getCompanies() {
    this.startLoading();
    if (this.state.companies.length === 0) {
      let companies = await this.setCompanies();
      this.finishLoading();
      if (companies) return this.state.companies;
    } else {
      this.finishLoading();
      return this.state.companies;
    }
  },
  setCompanies () {
    return new Promise((resolve, reject) => {
      CompaniesUtil.getSnapCompanies()
      .then(companies => {
        this.state.companies = this.sortFunc(companies);
        this.displaySnackBar("Loaded Companies Successfully.")
        resolve(true);
      })
      .catch(error => {
        reject(error);
      })
    })
  },
  startLoading() {
    this.state.loading = true;
  },
  finishLoading() {
    this.state.loading = false;
  },
  displaySnackBar(text) {
    this.state.snackbar = true;
    this.state.text = text;
  },
  sortFunc(array) {
    return array.slice().sort(function(a, b){
      return (a.name > b.name) ? 1 : -1;
    });
  },
  appVersion: (state) => {
    return state.packageVersion
  }
}

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false

Vue.use(vuetify);

new Vue({
  data: {
    privateState: {},
    sharedData: store
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')