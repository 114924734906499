
const userRoles = { 
    computed:{
    userRoles(){
        if(!this.$auth.isAuthenticated) return []
        return this.$auth.user && this.$auth.user['additional-info/roles']? this.$auth.user['additional-info/roles'] : []
    },
    adminUser(){
      const adminRoles = ['Billing-Admin', 'Billing-Dev']
      return adminRoles.some(role => this.userRoles.includes(role))
    },
    devUser() {
      const devRole = 'Billing-Dev'
      return this.userRoles.includes(devRole)
    },
    financeUser(){
      const roles = ['Billing-Admin', 'Billing-Dev', 'Billing-Finance-Only']
      return roles.some(role => this.userRoles.includes(role))
    },
    viewOnlyUser(){
      const roles = ['Billing-View-Only']
      return roles.some(role => this.userRoles.includes(role))
    }
}
};

export default userRoles;
