import { getInstance } from "./index";

export const authGuardAdmin = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with admin check
    if (authService.isAuthenticated) {
      const user = authService.user
      const userRoles = user['additional-info/roles'] ? user['additional-info/roles'] : []
      const adminRoles = ['Billing-Admin', 'Billing-Dev']
      // if user role is included on any of the admin roles then continue to load components otherwise return null
      if (adminRoles.some(role => userRoles.includes(role))
      ) {
        return next();
      }else{
         //do not load component in case the user is not an admin
        return null
      }
   
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
